<template>
  <div :class="`w-full h-full bg-right-top bg-no-repeat bg-cover ${wrapperClassPrefix}main-introduction main-intro-content`">
    <CommonTextImgeContentCard
      title="Canopy Home Exteriors"
      main-div-class=""
      :paragraphs="paragraphs"
      video="https://www.youtube.com/embed/cXhQ25eQ4mQ?si=AmFWDRR4EW1tX202"
      alt="Canopy Home Exteriors Introduction Video"
      :metrics-data="metricsData"
    />
    <MainWhyCanopy />
  </div>
</template>

<script setup lang="ts">
const paragraphs = [
  'At Canopy Home Exteriors, we blend passion and proficiency to redefine remodeling excellence. With many years of dedicated service, our team stands as a beacon of reliability and innovation in the industry. From lasting roof replacements to seamless window replacements, we’re committed to elevating your home’s protection with precision and care. Trust us to transform your vision into reality.',
  'At Canopy Home Exteriors, our mission is rooted in integrity and client satisfaction. Beyond mere home exterior services, we strive to build lasting relationships with our customers, ensuring transparency, communication, and trust at every step of the process. With a focus on continuous improvement and staying at the forefront of industry advancements, we pledge to always deliver results that exceed expectations.'
]

const metricsData = {
  title: 'We Are Proud to Say',
  metrics: [
    { key: 'Years of experience', value: '14', unit: '+' },
    { key: 'Completed projects', value: '750', unit: '+' },
    { key: '5 Star Reviews', value: '100', unit: '%' }
  ]
}

const { width } = useWindowSize()

const wrapperClassPrefix = computed(() => width.value >= 1025 ? 'lg-' : '')

</script>

<style scoped>
.main-intro-content {
  position: relative;
  background: #F6F6F6 no-repeat;
  z-index: 1;
  height: 100%;
}

.lg-main-introduction:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  z-index: -1;
}

.lg-main-introduction:before {
  background: #ECECEC;
  clip-path: polygon(100% 0%, 0 100%, 100% 100%);
  top: 0;
}
</style>
